import Vue from 'vue'
import Vuex from 'vuex'

import ApiService from "@/services/api_service";

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    jwt: '',
    user: {
      id: '',
      name: '',
      surname: '',
      sd_company: '',
      sd_ico: '',
      permissions: [],
    },
    search_string: '',
    cat: [],
		login_message: '-'
  },
  getters: {
    is_authenticated(state) {
      if (!state.jwt || state.jwt.split('.').length < 3) {
        return false
      }
      const data = JSON.parse(atob(state.jwt.split('.')[1]));
      const exp = new Date(data.exp * 1000);
      const now = new Date();
      return now < exp
    },
    get_jwt(state) {
      return state.jwt
    },
    get_user_data(state) {
      return JSON.parse(atob(state.jwt.split('.')[1])).additional_claims
    },
    get_user_id(state) {
      return state.user.id
    },
    get_user_name(state) {
      return state.user.name
    },
    get_user_surname(state) {
      return state.user.surname
    },
    get_user_sd_company(state) {
      return state.user.sd_company
    },
    get_user_sd_ico(state) {
      return state.user.sd_ico
    },
    get_user_permissions(state) {
      return state.user.permissions
    },
    get_search_string(state) {
      return state.search_string
    },
    get_cat_items(state) {
      return state.cat
    },
		get_login_message(state) {
			return state.login_message
		}
  },
  mutations: {
    set_auth_data(state, auth_data) {
      localStorage.setItem('AUTH_DATA_JWT', auth_data.jwt)
      state.jwt = auth_data.jwt
      ApiService.setHeader()
    },
    set_auth_token(state, token) {
      localStorage.setItem('AUTH_DATA_JWT', token)
      state.jwt = token
      ApiService.setHeader()
    },
    set_cat_data(state, data) {
      localStorage.setItem('CAT_DATA', data)
      state.cat = data
    },
    set_user_data(state, user_data) {
      state.user = user_data
    },
    clear_auth_data(state) {
      localStorage.clear()
      state.jwt = ''
      state.user = ''
      ApiService.setHeader()
    },
		set_login_message(state, msg) {
			state.login_message = msg
		},
    set_search_string(state, search_string) {
      state.search_string = search_string
    },
    add_to_catalogue(state, ID_pl) {
      var item_is_present = false
      for(var i = 0; i < state.cat.length; i++) {
        if(state.cat[i] == ID_pl) {
          item_is_present = true
          break
        }
      }
      if(!item_is_present) state.cat.push(ID_pl)
      localStorage.setItem('CAT_DATA', state.cat)
    },
    remove_from_catalogue(state, position) {
      state.cat.splice(position, 1)
      localStorage.setItem('CAT_DATA', state.cat)

    },
    change_position(state, payload) {
      var item = state.cat[payload.old_pos]
      state.cat.splice(payload.old_pos, 1)
      state.cat.splice(payload.new_pos, 0, item)
      localStorage.setItem('CAT_DATA', state.cat)
    }
  },
  actions: {
    login(context, user_login_data) {
      return ApiService.post(`/auth/login`, user_login_data).then(response => {
				context.commit('set_login_message', 'OK');
        context.commit('set_auth_data', response.data);
        context.commit('set_user_data', context.getters.get_user_data);

      }).catch(error => {
        context.commit('set_login_message', error.response.data.msg);
				context.commit('clear_auth_data')
      })
    },
    logout(context) {
			context.commit('set_login_message', '-');
      context.commit('clear_auth_data')
    },
    set_auth_data(context, auth_data) {
      context.commit('set_auth_data', auth_data);
      context.commit('set_user_data', context.getters.get_user_data);

    },
    set_auth_token(context, token) {
      context.commit('set_auth_token', token);
      context.commit('set_user_data', context.getters.get_user_data);
    },
    store_search_data(context, search_string) {
      // consider storing also the results
      context.commit('set_search_string', search_string)
    },
  },
  modules: {}
})
