import { store } from '@/store/store'

const AuthService = {
  is_authenticated() {
    return store.getters.is_authenticated
  },
  has_permission(permission) {
    return store.getters.get_user_permissions.includes(permission)
  },
  has_any_permission(permissions) {
    permissions.forEach(permission => {
      if (AuthService.has_permission(permission)) {
        return true;
      }
    })
    return false;
  }
}
export default AuthService