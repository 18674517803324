import axios from 'axios'

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    ApiService.setHeader();
  },
  setHeader() {
    if (localStorage.AUTH_DATA_JWT) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.AUTH_DATA_JWT
    }
    else {
      axios.defaults.headers.common = {}
    }
  },
  get(resource) {
    return axios.get(resource)
  },
  get_att(resource) {
    return axios.get(resource, { responseType: 'blob' })
  },
  post(resource, data) {
    return axios.post(resource, data)
  },
  post_sectro(resource, data) {
    return axios.post(resource, data, {})
  },
  post_attachments(resource, data) {
    return axios.post(resource, data, {headers: {'Content-Type': 'multipart/form-data'}})
  },
  put(resource, data) {
    return axios.put(resource, data)
  },
  delete(resource) {
    return axios.delete(resource)
  }
}
export default ApiService