import AuthService from "@/services/auth/auth_service";
import Permissions from "@/services/auth/permissions";

var AuthMixin = {
  data: () => ({
    permissions: Permissions
  }),
  methods: {
    isAuthenticated() {
      return AuthService.is_authenticated()
    },
    checkPermission(permission) {
      return AuthService.has_permission(permission)
    },
    hasAnyPermission(permission) {
      return AuthService.has_any_permission(permission)
    }
  },
  computed: {
    canViewProduct() {
      return this.checkPermission(Permissions.VIEW_PRODUCT)
    }
  }
};

export default AuthMixin
