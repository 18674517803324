<template>
	<v-container>
		<v-app-bar>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-btn route to="/" icon><v-icon>mdi-home</v-icon></v-btn>		
			<!-- <v-toolbar-title>My files</v-toolbar-title> -->
			<v-app-bar-title>
			<v-img class="medved" max-height="120px" contain src="/pictures/current_logo.svg"></v-img>
			</v-app-bar-title>
			<!-- <v-text-field v-model="search" placeholder="Search" @focus="searchClosed = false" @blur="searchClosed = true" :class="{ 'closed': searchClosed && !search }" prepend-inner-icon="mdi-magnify" class="mt-1 expanding-search" filled dense clearable></v-text-field> -->
			<!-- <v-menu close-on-click :close-on-content-click="false" :offset-y="0" :offset-x="0" :nudge-width="window_width">
					<template v-slot:activator="{ on }">
						<div class="user-menu-button pl-1 pr-4">
							<v-btn icon v-on="on" @click="calc()">
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</div>
					</template>
					<v-list flex>
						<v-list-item>
							<v-list-item-content>
								<v-row>
									<v-col sm-8>
								<v-text-field v-model="search" prepend-inner-icon="mdi-magnify" placeholder="Search"></v-text-field>
									</v-col><v-col sm-4>
								<v-btn icon><v-icon>mdi-arrow-right-bold</v-icon></v-btn>
								</v-col></v-row>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu> -->
			<v-btn route to="/search" icon :title="$t('site_search.btn_title_search')"><v-icon>mdi-magnify</v-icon></v-btn>		

			<v-btn v-if="!this.$store.getters.is_authenticated" route to="/login" icon><v-icon>mdi-login</v-icon></v-btn>		
			<v-menu v-else close-on-click close-on-content-click offset-y st>
					<template v-slot:activator="{ on }">
						<div class="user-menu-button pl-1 pr-4">
							<v-btn icon v-on="on">
								<v-icon>mdi-shield-account</v-icon>
							</v-btn>
						</div>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>{{ username }}</v-list-item-title>
								<v-list-item-subtitle>{{ orgname }}</v-list-item-subtitle>
								<v-list-item-subtitle>{{ ico }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<v-divider></v-divider>
						<v-list-item @click="edit">
							<v-list-item-icon>
								<v-icon>mdi-account-edit</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('user_menu.edit') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click="logout">
							<v-list-item-icon>
								<v-icon>mdi-logout</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('user_menu.logout') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer"	absolute temporary>
			<v-list	nav	dense>
				<v-list-item-group v-model="group" active-class="grey--text text--accent-4">
					<v-list-item route to="/productMenu">
						<v-list-item-icon>
							<v-icon>mdi-cart</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.products') }}</v-list-item-title>
					</v-list-item>
					<v-list-item route to="/enews">
						<v-list-item-icon>
							<v-icon>mdi-new-box</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.enews') }}</v-list-item-title>
					</v-list-item>
					<v-list-item route to="/events">
						<v-list-item-icon>
							<v-icon>mdi-presentation</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.events') }}</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="this.$store.getters.is_authenticated" route to="/education/overview">
						<v-list-item-icon>
							<v-icon>mdi-school</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.education') }}</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="this.$store.getters.is_authenticated" route to="/rmaOverview">
						<v-list-item-icon>
							<v-icon>mdi-bug-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.rma') }}</v-list-item-title>
					</v-list-item>
					<v-list-item route to="/references">
						<v-list-item-icon>
							<v-icon>mdi-handshake</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.references') }}</v-list-item-title>
					</v-list-item>
					<v-list-item route to="/suppliers">
						<v-list-item-icon>
							<v-icon>mdi-truck</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.suppliers') }}</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="this.$store.getters.is_authenticated" route to="/catalogue">
						<v-list-item-icon>
							<v-icon>mdi-folder-multiple</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.catalogue') }} ({{ this.$store.getters.get_cat_items.length}})</v-list-item-title>
					</v-list-item>
					<v-list-item route to="/contact">
						<v-list-item-icon>
							<v-icon>mdi-google-hangouts</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.contact') }}</v-list-item-title>
					</v-list-item>
					<v-list-item route to="/terms">
						<v-list-item-icon>
							<v-icon>mdi-book-open-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.terms') }}</v-list-item-title>
					</v-list-item>
					<v-list-item route to="/gdpr">
						<v-list-item-icon>
							<v-icon>mdi-shield-account</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ $t('menu.gdpr') }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</v-container>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
// import Permissions from "@/services/auth/permissions";
// import UserMenu from "@/components/UserMenu.vue"

export default {
	name: "MainMenu",
	components: {
	// UserMenu
	},
	data: () => ({
		dialog: false,
		
		drawer: false,
		group: null,
		window_width: 0,
		search: '',
	}),
	computed: {
		username() {
			return this.$store.getters.get_user_surname;
		},
		orgname() {
			return this.$store.getters.get_user_sd_company;
		},
		ico() {
			return this.$store.getters.get_user_sd_ico;
		}
	},
	watch: {
		group() {
			this.drawer = false;
		},
	},
	mixins: [AuthMixin],
	methods: {
		calc() {
			this.window_width = window.innerWidth - 100;
		},
		logout() {
			this.$store.dispatch("logout").then(() => {
				window.location.reload();
			});
		},
		edit() {
			this.$router.push({ path: '/edit' })
		},
		navClicked() {
			this.$root.$emit("nav-clicked");
		},
	},
};
</script>

<style lang="sass">
	.v-input.expanding-search
		transition: max-width 0.3s
		.v-input__slot
			cursor: pointer !important
			&:before, &:after
				border-color: transparent !important
		&.closed
			max-width: 45px
			.v-input__slot
				background: transparent !important
</style>
<style scoped>
.user-menu {
	display: inherit;
}

.user-menu-button {
	margin-right: 0;
	margin-left: 0;
	/*height: 46px !important;*/
}

.user-menu .v-badge__badge {
	height: 18px;
	min-width: 18px;
	top: 2px;
	right: 2px;
}
.medved {
	padding: 0;
}
</style>
