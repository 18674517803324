import Vue from 'vue'
import App from './App.vue'
import {router} from './router'
import {store} from '@/store/store'
import ApiService from '@/services/api_service'
import vuetify from './plugins/vuetify';
import { i18n } from "@/i18n/i18n";
import VueForceNextTick from 'vue-force-next-tick'


Vue.config.productionTip = false
Vue.use(VueForceNextTick)

ApiService.init(("$VUE_APP_SECTRO_CORE_API"[0] != "$") ? "$VUE_APP_SECTRO_CORE_API" : process.env.VUE_APP_SECTRO_CORE_API);

export const vm = new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
