const messages_sk = {
    user_menu: {
        logout: "Odhlásiť",
        edit: "Editovať"
    },
		roadshow: {
				title: "Školenia a prezentácie",
				subtitle: "Táto sekcia slúži pre prihlasovanie sa registrovaných používateľov na školenia a prezentácie organizované spoločnosťou SECTRO s.r.o.",
				training: "Školenie",
				presentation: "Prezentácia"
		},
    auth_edit: {
        tab_password: "Heslo",
        title_password: "Zmena hesla",
        password: "Heslo",
        password_new: "Nové heslo",
        password_new_confirm: "Nové heslo znova",
        btn_change_password: "Zmeniť",
        alert_password_changed: "Heslo bolo úspešne zmenené",

        tab_user: "Používateľ",
        title_user: "Editovanie údajov používateľa",
        name: "Meno",
        surname: "Priezvisko",
        company: "Spoločnosť",
        ico: "IČO",
        position: "Pozícia",
        telephone: "Tel. kontakt",
        email: "E-mail",
        enews: "Prajete si dostávať e-news?",
        enews_yes: "Áno",
        enews_no: "Nie",
        btn_change_user_data: "Uložiť",
        alert_user_data_changed: "Údaje používateľa boli úspešne zmenené",

        tab_certification: "Oprávnenia",
        title_certification: "Editovanie osobných údajov pre účely vydávania oprávnení",
        title_before: "Tituly pred menom",
        title_after: "Tituly za menom",
        birthday: "Dátum narodenia",
        address: "Adresa",
        zipcode: "PSČ",
        city: "Mesto",
        country: "Štát",
        licence_for: "Oprávnenie na:",
        licence_design: "Projektovanie elektrickej požiarnej signalizácie",
        licence_installation: "Zabudovanie elektrickej požiarnej signalizácie do stavby",
        licence_inspection: "Opravy a ročné kontroly elektrickej požiarnej signalizácie",
        btn_change_certification_data: "Uložiť",
        alert_certification_data_changed: "Údaje pre vydávanie oprávnení boli úspešne zmenené",
        btn_confirm_certification_data: "Potvrdiť platnosť údajov",
    },
    registration: {
        title: "Registrácia nového používateľa",
        name: "Meno",
        surname: "Priezvisko",
        company: "Spoločnosť",
        company_address: "Spoločnosť - adresa",
        ico: "IČO",
        position: "Pozícia",
        telephone: "Tel. kontakt",
        email: "E-mail",
        password: "Heslo",
        password_confirm: "Heslo znova",
        enews: "Prajete si dostávať e-news?",
        enews_yes: "Áno",
        enews_no: "Nie",
        gdpr: "V zmysle Zákona č. 122/2013 Z.z. o ochrane osobných údajov v znení neskorších predpisov súhlasím so správou, spracovaním a uchovaním osobných údajov uvedených v registrácii na stránke www.sectro.sk firmou SECTRO s.r.o., IČO 46507248 za účelom vydania osobitného oprávnenia, vypracovania cenovej ponuky a prístupu na chránenú časť web stránky.",
        btn_register: "Registrovať",
        alert_registration_request_success: "Vaša požiadavka o registráciu bola prijatá. O vytvorení konta Vás budeme informovať prostredníctvom e-mailu."
    },
    reset: {
        title: "Obnova hesla",
        email: "E-mail",
        btn_reset_password: "Obnoviť heslo",
        alert_password_reset_success: "Vaša požiadavka o obnovenie hesla bola odoslaná. Na Váš e-mail bude doručený URL odkaz na obnovu hesla.",
        alert_password_reset_error: "Počas spracovania Vašej požiadavky nastala chyba."
    },
    validation: {
        alpha: "Pole nesmie obsahovať číslice ani špeciálne znaky",
        custom_gdpr: "Musíte dať súhlas na spracovanie osobných údajov v zmysle nariadenia GDPR",
        custom_password: "Zadané heslá sa musia zhodovať",
        custom_telephone: "Pole musí obsahovať telefónne číslo v tvare +421XXXXXXXXX",
        digits: "Pole musí obsahovať {length} číslic",
        email: "Pole musí obsahovať platnú e-mailovú adresu",
        required: "Pole nesmie byť prázdne",
        custom_invoice: "Z dvojice údajov \"Číslo faktúry\" a \"Dátum nákupu\" musíte zadať aspoň jeden údaj."
    },
    catalogue: {
        title: "Generovanie katalógu",
        subtitle: "Služba \"Generovanie katalógu\" Vám poskytuje jedinečnú možnosť vytvorenia PDF dokumentu obsahujúceho katalógové listy jednotlivých produktov, ktoré Ste do katalógu pridali prostredníctvom sekcie \"Produkty\". Obsah katalógu a parametre prvej strany generovaného dokumentu spolu s kontaktnými údajmi možno konfigurovať prostredníctvom tejto sekcie.",
        tab_content: "Položky",
        tab_settings: "Nastavenia",
        content_is_empty: "Nevybrali ste žiadne produkty",

        ID_user: "ID používateľa",
        project_name: "Názov projektu",
        short_description: "Stručný popis projektu",
        web_contact: "Web kontakt",
        email_contact: "Mail kontakt",
        tel_contact: "Telefónny kontakt",
        pic_logo: "Firemné logo",
        pic_big: "Obrázok na obálke katalógu",
	color_scheme: "Farebný motív",

        btn_title_up: "Posun o pozíciu hore",
        btn_title_down: "Posun o pozíciu dole",
        btn_title_remove: "Odstrániť",
        btn_generate: "Vytvoriť PDF",
    },
    enews: {
        title: "Aktuality",
        loading: "Načítavam aktuality"
    },
    gdpr: {
        title: "GDPR",
        subtitle: "Nariadenie EÚ o ochrane osobných údajov"
    },
    terms: {
        title: "Obchodné podmienky",
				subtitle: "vydané dňa 1.1.2022 v zmysle § 273 obchodného zákonníku"
    },
    login: {
        title: "Prihlásenie ",
        username: "Používateľ",
        password: "Heslo",
        btn_login: "Prihlásiť",
        alert_login_data_wrong: "Nesprávne zadané údaje",
				alert_login_not_active: "Používateľské konto nebolo aktivované.",
        alert_login_failed: "Nastala neznáma chyba. Proces prosím opakujte neskôr.",
        btn_new_registration: "Nova registrácia",
        btn_reset_password: "Obnova hesla"
    },
    menu: {
        products: "Produkty",
        enews: "Aktuality",
				events: "Školenia a prezentácie",
        education: "Vzdelávanie",
        rma: "Reklamácie",
        references: "Referencie",
        suppliers: "Dodávatelia",
        catalogue: "Generovanie katalógu",
        contact: "Kontakty",
				terms: "Obchodné podmienky",
        gdpr: "GDPR"
    },
    product: {
        title_main: "Produkty",
        title_sub: "Kategórie produktov",
        loading: "Načítavam produkty v tejto kategórii, prosím čakajte.",
        loading_specific: "Načítavam údaje o produkte, prosím čakajte.",

        tab_description: "Popis",
        tab_properties: "Vlastnosti",
        tab_parameters: "Parametre",
        tab_files: "Súbory",
        tab_ordering: "Objednávanie",

        files_after_login: "Počet súborov po prihlásení",
        file_catalogue_sk: "Katalógový list (SK)",

        login_required: "Prístup",
        login_required_disclaimer: "Pre prístup ku všetkým súborom a materiálom sa prosím prihláste.",
        login_required_btn_cancel: "Zrušiť",
        login_required_btn_login: "Prihlásenie",

        btn_title_add_to_catalogue: "Pridať produkt do katalógu",
        alert_added_to_catalogue: "Produkt bol pridaný do katalógu",
    },
    reference: {
        title: "Referencie",
        loading: "Načítavam referenciu",
        loading_all: "Načítavam referencie",
        field: "Oblasť nasadenia",
        product_type: "Typ produktu:",
        filter_field: "Filtrovať oblasť nasadenia",
        filter_product_type: "Filtrovať typ produktu",
        selector: "Vybrať",
    },
    rma: {
        title: "Vytvorenie novej reklamácie",
        title_all: "Reklamácie",
        partner: "Informácie o reklamujúcom (Meno, priezvisko, spoločnosť, IČO)",
        product: "Produkt",
        serial_number: "Sériové číslo",
        serial_number_disclaimer: "(ak má produkt sériové číslo, je nutné ho uviesť)",
        invoice_disclaimer: "Z dvojice údajov \"Číslo faktúry\" a \"Dátum nákupu\" musíte zadať aspoň jeden údaj.",
        invoice_id: "Číslo faktúry",
        invoice_date: "Dátum nákupu",
        installation: "Miesto inštalácie",
        fault_description: "Popis poruchy",
        attachments: "Prílohy",
        attachments_disclaimer: "Môžete poslať len jeden .ZIP archív. Ak je súborov viac, prosím skomprimujte ich do jedného .ZIP súboru.",
        btn_send: "Odoslať reklamáciu",
        overview: "Prehľad nevybavených reklamácií",
        btn_new: "Vytvoriť reklamáciu",
        uid: "Č.",
        pl_code: "Produkt",
        status: "Stav",
	submission_date: "Dátum vytvorenia",
        search: "Vyhľadávanie",
        loading: "Načítavam reklamácie",
        status_created: "Vytvorená",
        status_in_progress: "Riešená",
        status_done: "Vybavená"
    },
    site_search: {
        title: "Vyhľadávanie na stránke",
        search_str: "Hľadaný reťazec",
        btn_title_search: "Vyhľadať",
        loading: "Vyhľadávam",
        results: "Výsledky",
        no_results: "Žiadne výsledky",
    },
    slides: {
        loading: "Načítavam"
    },
    suppliers: {
        title: "Dodávatelia",
        loading: "Načítavam dodávateľov"
    },
    system: {
        login_required: "Na prístup k tejto funkcionalite sa musíte prihlásiť.",
    },
    education: {
        loading_test: "Načítavam test",
        ID_test: "ID testu",
        training: "Školenie",
        instructor: "Školiteľ",
        trainee: "Vypracoval",
        instructions_title: "Pokyny",
        instructions: "Označte správnu odpoveď. V prípade viacerých správnych odpovedí je potrebné označiť všetky správne možnosti. Za každú správne označenú možnosť získate 1 bod. Za každú nesprávne označenú možnosť sa 1 bod odpočíta. Úspešné absolvovanie testu vyžaduje získanie minimálne 50% maximálneho počtu bodov.",
        btn_submit_test: "Odovzdať test",
        scroll_to_top: "Prejsť na začiatok",

        title_overview: "Vzdelávanie",
        header_label: "Test",
        header_status: "Stav",

        check: "Potvrdenie platnosti údajov",
        check_long: "Pre pokračovanie prosím skontrolujte platnosť osobných údajov potrebných pre vydanie oprávnenia po úspešnom absolvovaní testu",
        check_long_sub: "Bez potvrdenia platnosti týchto údajov nebudete môcť pokračovať.",
        check_redir: "Ďalej", 

        btn_run_test: "Spustiť test",
        status_fail: "Neabsolvoval",
        status_pass: "Absolvoval",

        actions: "Operácie",
    },
}

export default messages_sk
