<template>
	<v-app>
		<MainMenu />
		<v-main>
			<v-container fluid>
				<transition mode="out-in">
					<router-view :key="$route.fullPath" />
				</transition>
			</v-container>
		</v-main>
		<v-footer padless dense bottom>
			<v-col class="text-center" cols="12">{{ new Date().getFullYear() }} — <strong>SECTRO s.r.o.</strong></v-col>
		</v-footer>
	</v-app>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue"
import AuthMixin from "@/services/auth/auth_mixin";

export default {
	name: "App",
	components: {
		MainMenu,
	},
	data: () => ({
		url: process.env.VUE_APP_URL,
	}),
	mixins: [AuthMixin],
	methods: {},
	mounted() {
		if(localStorage.AUTH_DATA_JWT) {
			this.$store.dispatch('set_auth_token', localStorage.AUTH_DATA_JWT)
			if(this.$store.getters.is_authenticated) console.log(1)
			else {
				localStorage.removeItem('AUTH_DATA_JWT')
				this.$store.dispatch("logout")
				console.log(0)
			}
		}
		if(localStorage.CAT_DATA) {
			this.$store.dispatch('set_cat_data', localStorage.CAT_DATA)
		}
	},
};
</script>

<style scoped>
#app {
	background-image: url("/pictures/pics/body/bg.png");
	background-repeat: repeat;
}
</style>
