import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/store/store'
import AuthService from "@/services/auth/auth_service";
// import Permissions from "@/services/auth/permissions";
//asdf
Vue.use(Router);

export const router = new Router({
	mode: 'history',
	base: '/',
	duplicateNavigationPolicy: 'ignore',
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('./components/Home.vue'),
		},
		{
			path: '/catalogue',
			name: 'catalogue',
			component: () => import('./components/Catalogue.vue')
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('./components/Login.vue')	
		},
		{
			path: '/slides',
			name: 'slides',
			component: () => import('./components/Slides.vue')
		},
		{
			path: '/terms',
			name: 'terms',
			component: () => import('./components/Terms.vue')
		},
		{
			path: '/gdpr',
			name: 'gdpr',
			component: () => import('./components/Gdpr.vue')
		},
		{
			path: '/suppliers',
			name: 'suppliers',
			component: () => import('./components/Suppliers.vue')
		},
		{
			path: '/registration',
			name: 'registration',
			component: () => import('./components/AuthRegistration.vue')
		},
		{
			path: '/edit',
			name: 'edit',
			component: () => import('./components/AuthEdit.vue')
		},
		{
			path: '/edit/:tabid',
			name: 'edittab',
			component: () => import('./components/AuthEdit.vue')
		},
		{
			path: '/reset',
			name: 'reset',
			component: () => import('./components/AuthReset.vue')
		},
		{
			path: '/references',
			name: 'references',
			component: () => import('./components/References.vue')
		},
		{
			path: '/reference/:id',
			name: 'reference',
			component: () => import('./components/Reference.vue')
		},
		{
			path: '/rmaOverview',
			name: 'rmaOverview',
			component: () => import('./components/RmaOverview.vue')
		},
		{
			path: '/rmaNew',
			name: 'rmaNew',
			component: () => import('./components/RmaNew.vue')
		},
		{
			path: '/contact',
			name: 'contact',
			component: () => import('./components/Contact.vue')
		},
		{
			path: '/productCategory/:id',
			name: 'productCategory',
			component: () => import('./components/ProductCategory.vue')
		},
		{
			path: '/product/:id',
			name: 'product',
			component: () => import('./components/Product.vue'),
			// meta: { requiresAuth: true, requiresPerm: [Permissions.VIEW_PRODUCT] }
		},
		{
			path: '/product/:id/searchfor/:search_string',
			name: 'productsearch',
			component: () => import('./components/Product.vue')
		},
		{
			path: '/education/test/:id',
			name: 'test',
			component: () => import('./components/Test.vue')
		},
		{
			path: '/education/overview',
			name: 'testOverview',
			component: () => import('./components/TestOverview.vue')
		},
		{
			path: '/productMenu',
			name: 'productMenu',
			component: () => import('./components/ProductMenu.vue')
		},
		{
			path: '/search',
			name: 'search',
			component: () => import('./components/Search.vue')
		},
		{
			path: '/productSubMenu/:id',
			name: 'productSubMenu',
			component: () => import('./components/ProductSubMenu.vue')
		},
		{
			path: '/enews',
			name: 'enews',
			component: () => import('./components/Enews.vue')
		},
		{
			path: '/events',
			name: 'events',
			component: () => import('./components/Events.vue')
		}
	]
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!store.getters.isAuthenticated) {
			window.location = encodeURI(store.getters.getLoginURL)
		}
		else if (to.path === "/") {
			next({ path: "/cat" })
		} else {
			if (to.meta.requiresPerm.length > 0) {
				if (AuthService.hasAnyPermission(to.meta.requiresPerm)) {
					next()
				} else {
					next({ path: "/" })
				}
			} else {
				next()
			}
		}
	} else {
		next()
	}
});

